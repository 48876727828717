import { GET_ABOUT_CONTENT, GET_BUYER_QUESTIONS, GET_BUYING_DATA, GET_CONTACT_DATA, GET_CONTENT_PATH, GET_FINALIZING_DATA, GET_GENERAL_QUESTIONS, GET_HOME_CONTENT, GET_INSERTBID_CONTENT, GET_NEWSLETTER_CONTENT, GET_PRIVACY_POLICY, GET_SELL_ITEM_DATA, GET_SELLER_QUESTIONS, GET_SELLING_DATA, GET_TERMS_CONDITIONS, GET_WHAT_IS_INSERT_BID } from "./cmsTypes";


const initialState = {
    generalQ: [],
    sellerQ: [],
    buyerQ: [],
    privacyContent: [],
    termsContent: [],
    homeContent: [],
    insertContent: [],
    newsletterContent: [],
    insertBidContent: [],
    sellingData: [],
    buyingData: [],
    finalizingData: [],
    contactData: [],
    sellItemData: [],
    aboutusContent: [],
};

const cmsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_GENERAL_QUESTIONS:
            return {
                ...state,
                generalQ: action.payload,
            };
        case GET_SELLER_QUESTIONS:
            return {
                ...state,
                sellerQ: action.payload,
            };
        case GET_BUYER_QUESTIONS:
            return {
                ...state,
                buyerQ: action.payload,
            };
        case GET_PRIVACY_POLICY:
            return {
                ...state,
                privacyContent: action.payload,
            };
        case GET_TERMS_CONDITIONS:
            return {
                ...state,
                termsContent: action.payload,
            };
        case GET_HOME_CONTENT:
            return {
                ...state,
                homeContent: action.payload,
            };
        case GET_INSERTBID_CONTENT:
            return {
                ...state,
                insertContent: action.payload,
            };
        case GET_NEWSLETTER_CONTENT:
            return {
                ...state,
                newsletterContent: action.payload,
            };
        case GET_WHAT_IS_INSERT_BID:
            return {
                ...state,
                insertBidContent: action.payload,
            };
        case GET_SELLING_DATA:
            return {
                ...state,
                sellingData: action.payload,
            };
        case GET_BUYING_DATA:
            return {
                ...state,
                buyingData: action.payload,
            };
        case GET_FINALIZING_DATA:
            return {
                ...state,
                finalizingData: action.payload,
            };
        case GET_CONTACT_DATA:
            return {
                ...state,
                contactData: action.payload,
            };
        case GET_SELL_ITEM_DATA:
            return {
                ...state,
                sellItemData: action.payload,
            };
        case GET_ABOUT_CONTENT:
            return {
                ...state,
                aboutusContent: action.payload,
            };

        default:
            return state;
    }
};

export default cmsReducer;

