import { CATEGORY_SEARCH_VALUE, EDIT_ITEM_DEATIL, EDIT_ITEM_VALUE, ENTER_KEY_VAL, GET_ALL_AUCTION_GAMES, GET_ALL_CATEGORIES, GET_ALL_COMPLETED_AUCTIONS, GET_ALL_ENDING_AUCTIONS, GET_ALL_FEATURED_AUCTIONS, GET_ALL_NEW_AUCTIONS, GET_ALL_RESERVED_AUCTIONS, GET_ALL_SEARCHED_CATEGORY, GET_ALL_SELL_ITEMS, GET_ALL_SOLD_ITEMS, GET_ALL_USER_BIDS, GET_ALL_WATCH_LISTS, GET_AUCTION_SEARCH_NAMES, GET_CLOSEST_TOTAL, GET_CURRENT_BID_AUCTIONS, GET_GAME_DETAIL, GET_SEARCH_DATA, GET_TOTAL_ENDING, GO_TO_SELL_ITEM, ITEM_SEARCH_VALUE, LOST_DATA, RESET_TIME, USER_DELIVERY_STATUS, USER_DISPUTES, USER_SAVED_SEARCHES, USER_SHIPPING_ADDRESSES } from "./auctionTypes";


const initialState = {
    allgames: [],
    searchnames: [],
    lostData: false,
    searchValue: null,
    categoryValue: null,
    enterKeyVal: false,
    allSellItems: [],
    totalSellItems: 0,
    watchlists: [],
    savesearch: [],
    endingauctions: [],
    totalendings: 0,
    newauctions: [],
    reservedauctions: [],
    totalreserved: 0,
    allcategories: [],
    userallbids: [],
    totalallbids: 0,
    gamedetail: [],
    solditems: [],
    totalsold: 0,
    edititem: null,
    totalcategories: 0,
    allsearchCategories: null,
    editedItem: false,
    gotoSellItem: null,
    resetTime: false,
    totalnewest: 0,
    completedauctions: [],
    totalcompleted: 0,
    totalClosest: 0,
    allauctionCategories: [],
    featuredauctions: [],
    totalfeatured: 0,
    currentauctions: [],
    totalcurrent: 0,
    savedSearches: [],
    disputes:[],
    disputesTotal:0,
    addresses:[],
    addressesTotal:0,
    deliveryStatus:null,
    searchData: null,
};

export const auctionReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_AUCTION_GAMES:
            return {
                ...state,
                allgames: action.payload,
            };
        case GET_AUCTION_SEARCH_NAMES:
            return {
                ...state,
                searchnames: action.payload,
            };

        case ITEM_SEARCH_VALUE:
            return {
                ...state,
                searchValue: action.payload,
            };
        case CATEGORY_SEARCH_VALUE:
            return {
                ...state,
                categoryValue: action.payload,
            };
        case ENTER_KEY_VAL:
            return {
                ...state,
                enterKeyVal: action.payload,
            };
        case GET_ALL_SELL_ITEMS:
            return {
                ...state,
                allSellItems: action.payload.data,
                totalSellItems: action.payload.total,
            };
        case GET_ALL_WATCH_LISTS:
            return {
                ...state,
                watchlists: action.payload.watch_list,
                savesearch: action.payload.save_search,
            };
        case GET_ALL_ENDING_AUCTIONS:
            return {
                ...state,
                endingauctions: action.payload.data,
            };
        case GET_ALL_SEARCHED_CATEGORY:
            return {
                ...state,
                allsearchCategories: action.payload ? action.payload : state.allsearchCategories
            }
        case GET_ALL_NEW_AUCTIONS:
            return {
                ...state,
                newauctions: action.payload.data,
                totalnewest: action.payload.total
            };
        case GET_ALL_RESERVED_AUCTIONS:
            return {
                ...state,
                reservedauctions: action.payload.data,
                totalreserved: action.payload.total
            };
        case GET_ALL_CATEGORIES:
            return {
                ...state,
                allcategories: action.payload.data.filter((item) => item?.is_feature === true),
                allauctionCategories: action.payload.data,
                totalcategories: action.payload.total
            };
        case GET_GAME_DETAIL:
            return {
                ...state,
                gamedetail: action.payload
            };
        case GET_ALL_USER_BIDS:
            return {
                ...state,
                userallbids: action.payload.data,
                totalallbids: action.payload.total,
            };
        case GET_ALL_SOLD_ITEMS:
            return {
                ...state,
                solditems: action.payload.data,
                totalsold: action.payload.total
            };
        case EDIT_ITEM_DEATIL:
            return {
                ...state,
                edititem: action.payload
            };
        case EDIT_ITEM_VALUE:
            return {
                ...state,
                editedItem: action.payload
            };
        case GO_TO_SELL_ITEM:
            return {
                ...state,
                gotoSellItem: action.payload,
            };
        case RESET_TIME:
            return {
                ...state,
                resetTime: action.payload,
            };
        case GET_ALL_COMPLETED_AUCTIONS:
            return {
                ...state,
                completedauctions: action.payload.data,
                totalcompleted: action.payload.total
            };
        case GET_TOTAL_ENDING:
            return {
                ...state,
                totalendings: action.payload,
            }
        case GET_CLOSEST_TOTAL:
            return {
                ...state,
                totalClosest: action.payload,
            };

        case GET_ALL_FEATURED_AUCTIONS:
            return {
                ...state,
                featuredauctions: action.payload.data,
                totalfeatured: action.payload.total
            };
        case LOST_DATA:
            return {
                ...state,
                lostData: action.payload,
            };
        case GET_CURRENT_BID_AUCTIONS:
            return {
                ...state,
                currentauctions: action.payload.data,
                totalcurrent: action.payload.total,
            };
        case USER_SAVED_SEARCHES:
            return {
                ...state,
                savedSearches: action.payload
            };
        case USER_DISPUTES:
            return {
                ...state,
                disputes: action.payload.data,
                disputesTotal: action.payload.total
            }; 
        case USER_SHIPPING_ADDRESSES:
            return {
                ...state,
                addresses: action.payload.list,
                addressesTotal: action.payload?.total || 0
            }; 
        case USER_DELIVERY_STATUS:
            return {
                ...state,
                deliveryStatus:action.payload
            };
        case GET_SEARCH_DATA:
            return {
                ...state,
                searchData: action.payload,
            };       
        default:
            return state;
    }
};