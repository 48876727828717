export const GET_PROFILE_DATA = "GET_PROFILE_DATA";
export const USER_UPDATE = "USER_UPDATE";
export const GET_USER_PROFILE = "GET_USER_PROFILE";
export const LOGOUT = "LOGOUT";
export const IS_SUBMIT = "IS_SUBMIT";
export const GET_USER_BIDS_INFO = "GET_USER_BIDS_INFO";
export const GET_URL_PATH = "GET_URL_PATH";
export const IS_EDIT = "IS_EDIT";
export const USER_ADD_CARD = "USER_ADD_CARD";
export const GET_COMMENT_LIKES="GET_COMMENT_LIKES";
