export const GET_GENERAL_QUESTIONS = "GET_GENERAL_QUESTIONS";
export const GET_BUYER_QUESTIONS = "GET_BUYER_QUESTIONS";
export const GET_SELLER_QUESTIONS = "GET_SELLER_QUESTIONS";
export const GET_PRIVACY_POLICY = "GET_PRIVACY_POLICY";
export const GET_TERMS_CONDITIONS = "GET_TERMS_CONDITIONS";
export const GET_HOME_CONTENT = "GET_HOME_CONTENT";
export const GET_INSERTBID_CONTENT = "GET_INSERTBID_CONTENT";
export const GET_NEWSLETTER_CONTENT = "GET_NEWSLETTER_CONTENT";
export const GET_WHAT_IS_INSERT_BID = "GET_WHAT_IS_INSERT_BID";
export const GET_SELLING_DATA = "GET_SELLING_DATA";
export const GET_BUYING_DATA = "GET_BUYING_DATA";
export const GET_FINALIZING_DATA = "GET_FINALIZING_DATA";
export const GET_CONTACT_DATA = "GET_CONTACT_DATA";
export const GET_SELL_ITEM_DATA = "GET_SELL_ITEM_DATA";
export const GET_ABOUT_CONTENT = "GET_ABOUT_CONTENT";